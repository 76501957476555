import { RootState } from '..';

export const currentProgressPlayedSecondsSelector = (state: RootState) =>
  state.progress.playedSeconds;

export const currentProgressPlayedSelector = (state: RootState) =>
  state.progress.played;

export const currentProgressLoadedSecondsSelector = (state: RootState) =>
  state.progress.loadedSeconds;

export const currentProgressLoadedSelector = (state: RootState) =>
  state.progress.loaded;

export const currentLineIndexSelector = (state: RootState) =>
  state.progress.lineIndex;
