export const RESULTS = {
  excellent: {
    markedText: 'Ты лучший!',
    text: 'С таким талантом не пропадешь: ты не только быстрый, но и сообразительный — достойные и ценные качества!',
    appeal:
      'Помоги СИБУРу стать лучшим – поддержи нас в Рейтинге работодателей hh.ru',
  },
  satisfactory: {
    markedText: 'Ты достойный соперник!',
    text: 'Ещё немного – и ты станешь настоящим мастером своего дела! Дерзай и все получится!',
    appeal:
      'А пока проголосуй за СИБУР – помоги нам стать лучшим работодателем России!',
  },
  badly: {
    markedText: 'Всё оказалось не так просто, но не стоит огорчаться!',
    text: ' В искусстве нет проигравших: ты проверил свои силы и получил достойную цель для роста.',
    appeal:
      'Помоги СИБУРу достигнуть цели – стать лучшим работодателем России!',
  },
  forSing: {
    markedText: 'С хорошей песней любое дело веселее!',
    text: '',
    appeal: 'Поддержи СИБУР – помоги нам стать лучшим работодателем России!',
  },
};
