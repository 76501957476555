import { useEffect, useState } from 'react';
import styles from './GameTimer.module.scss';

type TProps = {
  onTimerEnded: () => void;
  onCountdownChange: (n: number) => void;
  timerId: ReturnType<typeof setTimeout> | null;
  countdown: number;
};

const GAME_MAX_TIME = 60;

export const GameTimer = ({
  timerId,
  onCountdownChange,
  countdown,
}: TProps) => {
  const [currentNumber, setCurrentNumber] = useState(countdown);

  useEffect(() => {
    setCurrentNumber(countdown);
  }, [countdown]);

  useEffect(() => {
    const timer = () => {
      // if (currentNumber >= GAME_MAX_TIME) {
      //   timerId && clearInterval(timerId);
      //   onTimerEnded();
      //   return;
      // }

      setCurrentNumber((prev) => prev + 1);
    };

    timerId = setInterval(timer, 1000);
    onCountdownChange(currentNumber);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [currentNumber]);

  return (
    <div className={styles.timer}>
      <div className={styles.timer__text}>Твоё время:</div>
      <div className={styles.timer__time}>
        {`${Math.floor(currentNumber / GAME_MAX_TIME)}`.padStart(2, '0')}:
        {`${currentNumber % GAME_MAX_TIME}`.padStart(2, '0')}
      </div>
    </div>
  );
};
