import { TAnswer } from '@/src/pages/Game/data';
import styles from './AnswerButton.module.scss';
import cn from 'classnames';

type TProps = {
  onClick: (answer: TAnswer) => void;
  answer: TAnswer;
  className?: string;
  isSelected: boolean;
};

export const AnswerButton = ({
  answer,
  className,
  onClick,
  isSelected,
}: TProps) => {
  const { text, isTrue } = answer;

  const handleButtonClick = () => {
    onClick(answer);
  };

  return (
    <button
      className={cn(styles.button, {
        [`${className}`]: className !== undefined,
        ['correctly']: isSelected && isTrue,
        ['incorrectly']: isSelected && !isTrue,
      })}
      onClick={handleButtonClick}
      type="button"
    >
      {text}
    </button>
  );
};
