import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type TPageContext = {
  page: string;
  setPage: Dispatch<SetStateAction<string>>;
};

export const PageContext = createContext<TPageContext | null>(null);

export const usePageContext = () => useContext(PageContext);
