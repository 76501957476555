import styles from './Game.module.scss';
import { Preloader } from '@/src/components/Preloader';
import { GameTimer } from './components/GameTimer/GameTimer';
import { Container } from '@/src/components/Container';
import { GameProgress } from './components/GameProgress';
import { Question } from './components/Question';
import cn from 'classnames';
import ReactPlayer from 'react-player';

export type TQuestionResult = {
  id: number;
  isCorrectly: boolean;
};

import { useRef, useState } from 'react';
import { SongText } from './components/SongText';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/src/hooks';
import {
  currentGameTypeSelector,
  currentSongSlugSelector,
} from '@/src/store/selectors/game';
import { setNumberCorrectAnswers } from '@/src/store/slices/gameSlice';
import { QUESTIONS } from './data';
import { GAME_TYPE } from '@/src/constants';
import {
  resetProgress,
  setLoaded,
  setLoadedSeconds,
  setPlayed,
  setPlayedSeconds,
} from '@/src/store/slices/progressSlice';
import { OnProgressProps } from 'react-player/base';
import { isMobile } from 'react-device-detect';
import { currentProgressPlayedSecondsSelector } from '@/src/store/selectors/progress';

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();
  const gameType = useAppSelector(currentGameTypeSelector);
  const songSlug = useAppSelector(currentSongSlugSelector);
  const isGameTypeIsSing = gameType === GAME_TYPE.SING;
  const isGameTypeIsGuessWord = gameType === GAME_TYPE.GUESS_WORD;

  const [gameTime, setGameTime] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(
    isGameTypeIsSing
      ? QUESTIONS.findIndex((question) => question.id === songSlug)
      : 0,
  );
  const [isStartGame, setIsStartGame] = useState(false);

  const timerGameId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const player = useRef<ReactPlayer | null>(null);

  const handleGameTimeChange = (n: number) => {
    setGameTime(n);
  };

  const handleNextQuestionButtonClick = (value: number) => {
    dispatch(setNumberCorrectAnswers(value));

    if (currentQuestion === QUESTIONS.length - 1) {
      changePage('final');
    } else {
      setCurrentQuestion((prev) => prev + 1);
      dispatch(resetProgress());
    }
  };

  const startGame = () => {
    setIsStartGame(true);
  };

  const handleProgress = ({
    playedSeconds,
    played,
    loadedSeconds,
    loaded,
  }: OnProgressProps) => {
    dispatch(setPlayedSeconds(playedSeconds));
    dispatch(setPlayed(played));
    dispatch(setLoadedSeconds(loadedSeconds));
    dispatch(setLoaded(loaded));
  };
  const progressPlayedSeconds = useAppSelector(
    currentProgressPlayedSecondsSelector,
  );

  return (
    <div className={styles.game}>
      <div className={styles.wrapper}>
        {!isStartGame ? (
          <Preloader onPreloadEnded={startGame} />
        ) : (
          <>
            {!isGameTypeIsSing && (
              <div className={styles.game__header}>
                <Container className={styles.game__flex}>
                  <GameTimer
                    onTimerEnded={() => {}}
                    timerId={timerGameId.current}
                    onCountdownChange={handleGameTimeChange}
                    countdown={gameTime}
                  />
                  <GameProgress
                    currentCount={currentQuestion + 1}
                    totalCount={QUESTIONS.length}
                  />
                </Container>
              </div>
            )}
            <div
              className={cn(styles.game__main, {
                ['sing-game']: isGameTypeIsSing,
              })}
            >
              <Container>
                <div className={styles.game__video}>
                  <ReactPlayer
                    url={[
                      QUESTIONS[currentQuestion].song.mp4,
                      QUESTIONS[currentQuestion].song.webm,
                    ]}
                    playing
                    preload={isMobile ? 'metadata' : 'none'}
                    progressInterval={100}
                    onProgress={handleProgress}
                    ref={player}
                    muted={
                      (isGameTypeIsGuessWord &&
                        progressPlayedSeconds >=
                          QUESTIONS[currentQuestion].mutedStart &&
                        progressPlayedSeconds <
                          QUESTIONS[currentQuestion].mutedEnd) ||
                      false
                    }
                    onEnded={() => {
                      if (isGameTypeIsSing) {
                        changePage('final');
                      }
                    }}
                  />
                </div>
                {!isGameTypeIsSing ? (
                  <Question
                    question={QUESTIONS[currentQuestion]}
                    onAnswerButtonClick={handleNextQuestionButtonClick}
                    className={styles.game__question}
                    isGuessWordGameType={isGameTypeIsGuessWord}
                  />
                ) : QUESTIONS[currentQuestion].songTextLines.length ? (
                  <SongText
                    className={styles.question__songtext}
                    text={QUESTIONS[currentQuestion].songTextLines}
                  />
                ) : null}
              </Container>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
