import { useState } from 'react';
// import { TQuestionResult } from '../../Game';
import { AnswerButton } from './components/AnswerButton';
import { Progress } from './components/Progress';
import styles from './Question.module.scss';
import cn from 'classnames';
import { SongText } from '../SongText';
import { TAnswer, TQuestion } from '../../data';
import { useAppSelector } from '@/src/hooks';
import { currentProgressPlayedSelector } from '@/src/store/selectors/progress';

type TProps = {
  question: TQuestion;
  className?: string;
  onAnswerButtonClick: (value: number) => void;
  isGuessWordGameType: boolean;
};

export const Question = ({
  question,
  className,
  onAnswerButtonClick,
  isGuessWordGameType,
}: TProps) => {
  const progressPlayed = useAppSelector(currentProgressPlayedSelector);
  const [selectedAnswer, setSelectedAnswer] = useState<TAnswer | null>(null);

  const { songTextLines, answers, words } = question;

  const handleNextButtonClick = () => {
    onAnswerButtonClick(selectedAnswer?.isTrue ? 1 : 0);
    setSelectedAnswer(null);
  };

  const handleAnswerButtonClick = (answer: TAnswer) => {
    setSelectedAnswer(answer);
  };

  return (
    <div
      className={cn(styles.question, {
        [`${className}`]: className !== undefined,
      })}
    >
      {selectedAnswer !== null && (
        <button
          className={styles.question__next}
          type="button"
          onClick={handleNextButtonClick}
        >
          <span>Дальше</span>
          <span className={styles.question__icon}>
            <svg
              width="13"
              height="16"
              viewBox="0 0 13 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289L6.34315 0.928932C5.95262 0.538408 5.31946 0.538408 4.92893 0.928932C4.53841 1.31946 4.53841 1.95262 4.92893 2.34315L10.5858 8L4.92893 13.6569C4.53841 14.0474 4.53841 14.6805 4.92893 15.0711C5.31946 15.4616 5.95262 15.4616 6.34315 15.0711L12.7071 8.70711ZM0 9H12V7L0 7L0 9Z" />
            </svg>
          </span>
        </button>
      )}
      <Progress
        className={styles.question__progress}
        progress={progressPlayed * 100}
      />

      {songTextLines.length ? (
        <SongText
          className={styles.question__songtext}
          text={[...songTextLines]}
          isGuessWordGameType={isGuessWordGameType}
        />
      ) : null}

      <div
        className={cn(styles.question__answers, {
          ['disabled']: selectedAnswer !== null,
        })}
      >
        {!isGuessWordGameType &&
          answers.map((answer) => (
            <AnswerButton
              key={answer.id}
              className={styles.question__answer}
              answer={answer}
              onClick={handleAnswerButtonClick}
              isSelected={selectedAnswer?.id === answer.id}
            />
          ))}
        {isGuessWordGameType &&
          words.map((word) => (
            <AnswerButton
              key={word.id}
              className={styles.question__answer}
              answer={word}
              onClick={handleAnswerButtonClick}
              isSelected={selectedAnswer?.id === word.id}
            />
          ))}
      </div>
    </div>
  );
};
