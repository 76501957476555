import zivertMP4 from '@/src/videos/zivert.mp4';
import zivertWEBM from '@/src/videos/zivert.webm';

import chelseMP4 from '@/src/videos/chelse.mp4';
import chelseWEBM from '@/src/videos/chelse.webm';

import dancingMinusMP4 from '@/src/videos/dancing-minus.mp4';
import dancingMinusWEBM from '@/src/videos/dancing-minus.webm';

import iowaMP4 from '@/src/videos/iowa.mp4';
import iowaWEBM from '@/src/videos/iowa.webm';

import kraimbreryMP4 from '@/src/videos/kraimbrery.mp4';
import kraimbreryWEBM from '@/src/videos/kraimbrery.webm';

import teaTogetherMP4 from '@/src/videos/tea-together.mp4';
import teaTogetherWEBM from '@/src/videos/tea-together.webm';

export type TSongTextLinePart = {
  id: number;
  text: string;
  start: number;
  end: number;
  isHidden?: boolean;
};

export type TSongTextLine = {
  startLineTime: number;
  endLineTime: number;
  parts: TSongTextLinePart[];
};

export type TAnswer = {
  id: number;
  text: string;
  isTrue: boolean;
};

export type TQuestion = {
  id: string;
  song: {
    mp4: string;
    webm: string;
  };
  songTextLines: TSongTextLine[];
  answers: TAnswer[];
  words: TAnswer[];
  mutedStart: number;
  mutedEnd: number;
};

export const QUESTIONS: TQuestion[] = [
  {
    id: 'zivert',
    song: {
      mp4: zivertMP4,
      webm: zivertWEBM,
    },
    songTextLines: [
      {
        startLineTime: 0.646,
        endLineTime: 4.422,
        parts: [
          {
            id: 1,
            text: 'Знаешь',
            start: 0.646,
            end: 1.25,
          },
          {
            id: 2,
            text: 'ты',
            start: 1.274,
            end: 1.754,
          },
          {
            id: 3,
            text: 'кто такой',
            start: 1.898,
            end: 2.698,
          },
          {
            id: 4,
            text: 'Вивилен?',
            start: 2.822,
            end: 4.422,
          },
        ],
      },
      {
        startLineTime: 4.846,
        endLineTime: 8.212,

        parts: [
          {
            id: 1,
            text: 'Это',
            start: 4.846,
            end: 5.246,
          },
          {
            id: 2,
            text: 'не',
            start: 5.47,
            end: 5.67,
          },
          {
            id: 3,
            text: 'десерт',
            start: 5.894,
            end: 6.664,
          },
          {
            id: 4,
            text: 'и не',
            start: 6.688,
            end: 6.988,
          },
          {
            id: 5,
            text: 'крем',
            start: 7.212,
            end: 8.212,
          },
        ],
      },
      {
        startLineTime: 8.936,
        endLineTime: 12.2,
        parts: [
          {
            id: 1,
            text: 'не министр',
            start: 8.936,
            end: 10.036,
          },
          {
            id: 2,
            text: 'и не',
            start: 10.16,
            end: 10.61,
          },
          {
            id: 3,
            text: 'супермен',
            start: 10.7,
            end: 12.2,
          },
        ],
      },
      {
        startLineTime: 12.65,
        endLineTime: 15.424,
        parts: [
          {
            id: 1,
            text: 'Джаст',
            start: 12.65,
            end: 13.4,
          },
          {
            id: 2,
            text: 'инновация!',
            start: 13.424,
            end: 15.424,
            isHidden: true,
          },
        ],
      },
    ],
    answers: [
      { id: 1, text: 'Star - MARUV', isTrue: false },
      { id: 2, text: 'Танцуй - Artik & Asti', isTrue: false },
      { id: 3, text: 'Life - Zivert', isTrue: true },
    ],
    words: [
      { id: 1, text: 'феномен', isTrue: false },
      { id: 2, text: 'открытие', isTrue: false },
      { id: 3, text: 'инновация', isTrue: true },
    ],

    mutedStart: 13.36,
    mutedEnd: 15.424,
  },

  {
    id: 'chelse',
    song: {
      mp4: chelseMP4,
      webm: chelseWEBM,
    },
    songTextLines: [
      {
        startLineTime: 25.57,
        endLineTime: 28.47,
        parts: [
          {
            id: 1,
            text: 'Проектирую',
            start: 25.57,
            end: 26.37,
          },
          {
            id: 2,
            text: 'идеи,',
            start: 26.594,
            end: 26.894,
          },
          {
            id: 3,
            text: 'все',
            start: 27.04,
            end: 27.11,
          },
          {
            id: 4,
            text: 'в работе',
            start: 27.25,
            end: 27.634,
          },
          {
            id: 5,
            text: 'навзлёт,',
            start: 27.87,
            end: 28.47,
          },
        ],
      },
      {
        startLineTime: 28.49,
        endLineTime: 31.5,
        parts: [
          {
            id: 1,
            text: 'Я люблю',
            start: 28.49,
            end: 29.09,
          },
          {
            id: 2,
            text: 'свою',
            start: 29.25,
            end: 29.55,
          },
          {
            id: 3,
            text: 'команду,',
            start: 29.6,
            end: 30.1,
          },
          {
            id: 4,
            text: 'наш путь',
            start: 30.15,
            end: 30.53,
          },
          {
            id: 5,
            text: 'только',
            start: 30.56,
            end: 30.78,
          },
          {
            id: 6,
            text: 'вперёд.',
            start: 30.9,
            end: 31.5,
          },
        ],
      },
      {
        startLineTime: 31.72,
        endLineTime: 34.8,
        parts: [
          {
            id: 1,
            text: 'А награды',
            start: 31.72,
            end: 32.37,
          },
          {
            id: 2,
            text: 'и',
            start: 32.5,
            end: 32.6,
          },
          {
            id: 22,
            text: 'признание',
            start: 32.7,
            end: 33.18,
            isHidden: true,
          },
          {
            id: 3,
            text: 'здесь',
            start: 33.3,
            end: 33.5,
          },
          {
            id: 4,
            text: 'не просто',
            start: 33.55,
            end: 33.95,
          },
          {
            id: 5,
            text: 'мечты,',
            start: 34.1,
            end: 34.8,
          },
        ],
      },
      {
        startLineTime: 34.82,
        endLineTime: 37.92,
        parts: [
          {
            id: 1,
            text: 'Дальше – больше,',
            start: 34.82,
            end: 35.42,
          },
          {
            id: 2,
            text: 'в центре',
            start: 35.6,
            end: 35.9,
          },
          {
            id: 3,
            text: 'этого',
            start: 36,
            end: 36.4,
          },
          {
            id: 4,
            text: 'всего',
            start: 36.57,
            end: 36.93,
          },
          {
            id: 5,
            text: 'только ты',
            start: 36.98,
            end: 37.92,
          },
        ],
      },
      {
        startLineTime: 37.94,
        endLineTime: 40.9,
        parts: [
          {
            id: 1,
            text: 'каждый день',
            start: 37.94,
            end: 38.54,
          },
          {
            id: 2,
            text: 'у нас',
            start: 38.7,
            end: 38.9,
          },
          {
            id: 3,
            text: 'в Сибуре',
            start: 38.92,
            end: 39.42,
          },
          {
            id: 4,
            text: '- это',
            start: 39.54,
            end: 39.74,
          },
          {
            id: 5,
            text: 'новый',
            start: 39.9,
            end: 40.2,
          },
          {
            id: 6,
            text: 'рывок',
            start: 40.3,
            end: 40.9,
          },
        ],
      },
      {
        startLineTime: 41.1,
        endLineTime: 44.1,
        parts: [
          {
            id: 1,
            text: 'вместе',
            start: 41.1,
            end: 41.35,
          },
          {
            id: 2,
            text: 'мы',
            start: 41.48,
            end: 41.58,
          },
          {
            id: 22,
            text: 'преодолеем',
            start: 41.7,
            end: 42.59,
          },
          {
            id: 3,
            text: 'любой',
            start: 42.7,
            end: 42.89,
          },
          {
            id: 4,
            text: 'сложности',
            start: 43,
            end: 43.5,
          },
          {
            id: 5,
            text: 'урок.',
            start: 43.7,
            end: 44.1,
          },
        ],
      },
      {
        startLineTime: 44.1,
        endLineTime: 47.1,
        parts: [
          {
            id: 1,
            text: 'Ну а если',
            start: 44.1,
            end: 44.8,
          },
          {
            id: 2,
            text: 'не случится,',
            start: 45,
            end: 45.6,
          },
          {
            id: 3,
            text: 'не сдавайся,',
            start: 45.8,
            end: 46.4,
          },
          {
            id: 4,
            text: 'держись,',
            start: 46.5,
            end: 47.1,
          },
        ],
      },
      {
        startLineTime: 47.2,
        endLineTime: 50.4,
        parts: [
          {
            id: 1,
            text: 'всё получится',
            start: 47.2,
            end: 48.1,
          },
          {
            id: 2,
            text: 'назавтра,',
            start: 48.2,
            end: 48.75,
          },
          {
            id: 3,
            text: 'такова',
            start: 48.8,
            end: 49.3,
          },
          {
            id: 4,
            text: 'в СИБУРе',
            start: 49.4,
            end: 49.8,
          },
          {
            id: 5,
            text: 'жизнь',
            start: 49.9,
            end: 50.4,
          },
        ],
      },
      {
        startLineTime: 50.4,
        endLineTime: 53.3,
        parts: [
          {
            id: 1,
            text: 'Ты',
            start: 50.4,
            end: 51,
          },
          {
            id: 2,
            text: 'такая',
            start: 51.1,
            end: 51.7,
          },
          {
            id: 3,
            text: 'важная',
            start: 51.8,
            end: 53.3,
          },
        ],
      },
      {
        startLineTime: 53.5,
        endLineTime: 56.2,
        parts: [
          {
            id: 1,
            text: 'и',
            start: 53.5,
            end: 54,
          },
          {
            id: 2,
            text: 'такая',
            start: 54.1,
            end: 54.6,
          },
          {
            id: 3,
            text: 'ценная',
            start: 54.7,
            end: 56.2,
          },
        ],
      },
      {
        startLineTime: 56.4,
        endLineTime: 59.1,
        parts: [
          {
            id: 1,
            text: 'если быть',
            start: 56.4,
            end: 57.45,
          },
          {
            id: 2,
            text: 'точнее',
            start: 57.5,
            end: 58.1,
          },
          {
            id: 3,
            text: 'нам',
            start: 58.3,
            end: 59.1,
          },
        ],
      },
      {
        startLineTime: 59.5,
        endLineTime: 62.5,
        parts: [
          {
            id: 1,
            text: 'то скорей',
            start: 59.5,
            end: 60.4,
          },
          {
            id: 2,
            text: 'бесценная',
            start: 60.6,
            end: 62.5,
          },
        ],
      },
      {
        startLineTime: 62.7,
        endLineTime: 65.6,
        parts: [
          {
            id: 1,
            text: 'Вновь',
            start: 62.7,
            end: 63.26,
          },
          {
            id: 2,
            text: 'зовёт',
            start: 63.4,
            end: 63.85,
          },
          {
            id: 3,
            text: 'СИБУР',
            start: 63.9,
            end: 64.4,
          },
          {
            id: 3,
            text: 'меня',
            start: 64.6,
            end: 65.6,
          },
        ],
      },
      {
        startLineTime: 62.7,
        endLineTime: 68.6,
        parts: [
          {
            id: 1,
            text: 'там',
            start: 65.8,
            end: 66.2,
          },
          {
            id: 2,
            text: 'работают',
            start: 66.4,
            end: 67.25,
          },
          {
            id: 3,
            text: 'друзья,',
            start: 67.4,
            end: 68.6,
          },
        ],
      },
      {
        startLineTime: 68.9,
        endLineTime: 71.75,
        parts: [
          {
            id: 1,
            text: 'самая',
            start: 68.9,
            end: 69.9,
          },
          {
            id: 2,
            text: 'моя',
            start: 70.1,
            end: 70.4,
          },
          {
            id: 3,
            text: 'моя',
            start: 70.55,
            end: 71.75,
          },
        ],
      },
      {
        startLineTime: 72,
        endLineTime: 75.3,
        parts: [
          {
            id: 1,
            text: 'работа',
            start: 72,
            end: 72.5,
          },
          {
            id: 2,
            text: 'перспективная',
            start: 72.6,
            end: 75.3,
          },
        ],
      },
    ],
    answers: [
      { id: 1, text: 'Малыш - Данко', isTrue: false },
      { id: 2, text: 'Самая любимая - Челси', isTrue: true },
      { id: 3, text: 'Сохрани меня - Инь-Ян', isTrue: false },
    ],
    words: [
      { id: 1, text: 'призвание', isTrue: false },
      { id: 2, text: 'признание', isTrue: true },
      { id: 3, text: 'принятие', isTrue: false },
    ],

    mutedStart: 32.4,
    mutedEnd: 33.18,
  },

  {
    id: 'dancing-minus',
    song: {
      mp4: dancingMinusMP4,
      webm: dancingMinusWEBM,
    },
    songTextLines: [
      {
        startLineTime: 11.8,
        endLineTime: 14,
        parts: [
          {
            id: 1,
            text: 'Менделеев Дмитрий',
            start: 11.8,
            end: 14,
          },
        ],
      },
      {
        startLineTime: 14.3,
        endLineTime: 16.9,
        parts: [
          {
            id: 1,
            text: 'в Сколково',
            start: 14.3,
            end: 15.4,
          },
          {
            id: 2,
            text: 'не бывал',
            start: 15.6,
            end: 16.9,
          },
        ],
      },
      {
        startLineTime: 17,
        endLineTime: 19.7,
        parts: [
          {
            id: 1,
            text: 'что сказал бы',
            start: 17,
            end: 18.5,
          },
          {
            id: 2,
            text: 'учёный',
            start: 18.6,
            end: 19.7,
            isHidden: true,
          },
        ],
      },
      {
        startLineTime: 19.9,
        endLineTime: 22.4,
        parts: [
          {
            id: 1,
            text: 'коль',
            start: 19.9,
            end: 20.2,
          },
          {
            id: 2,
            text: 'полилаб',
            start: 20.3,
            end: 20.95,
          },
          {
            id: 3,
            text: 'увидал',
            start: 21.1,
            end: 22.4,
          },
        ],
      },
      {
        startLineTime: 22.7,
        endLineTime: 24.9,
        parts: [
          {
            id: 1,
            text: 'выпил б водки',
            start: 22.7,
            end: 23.75,
          },
          {
            id: 2,
            text: '100 грамм',
            start: 23.9,
            end: 24.9,
          },
        ],
      },
      {
        startLineTime: 25,
        endLineTime: 28.1,
        parts: [
          {
            id: 1,
            text: 'за полимерный',
            start: 25,
            end: 26.7,
          },
          {
            id: 2,
            text: 'успех',
            start: 26.9,
            end: 28.1,
          },
        ],
      },
      {
        startLineTime: 28.3,
        endLineTime: 30.3,
        parts: [
          {
            id: 1,
            text: 'и на память',
            start: 28.3,
            end: 29.4,
          },
          {
            id: 2,
            text: 'забрал',
            start: 29.5,
            end: 30.3,
          },
        ],
      },
      {
        startLineTime: 36.3,
        endLineTime: 37.4,
        parts: [
          {
            id: 1,
            text: 'из вторсырья',
            start: 36.3,
            end: 37.4,
          },
          {
            id: 2,
            text: 'образец',
            start: 37.5,
            end: 38.55,
          },
        ],
      },
    ],
    answers: [
      { id: 1, text: 'Мало половин - Ольга Бузова', isTrue: false },
      { id: 2, text: 'Половинка - Танцы Минус', isTrue: true },
      { id: 3, text: 'Половина - Клава Кока', isTrue: false },
    ],
    words: [
      { id: 1, text: 'химик', isTrue: false },
      { id: 2, text: 'учёный', isTrue: true },
      { id: 3, text: 'физик', isTrue: false },
    ],
    mutedStart: 18.4,
    mutedEnd: 19.7,
  },

  {
    id: 'iowa',
    song: {
      mp4: iowaMP4,
      webm: iowaWEBM,
    },
    songTextLines: [
      {
        startLineTime: 13.15,
        endLineTime: 16.9,
        parts: [
          {
            id: 1,
            text: 'На стажировку',
            start: 13.15,
            end: 14.45,
          },
          {
            id: 2,
            text: 'лечу,',
            start: 14.7,
            end: 15,
          },
          {
            id: 3,
            text: 'ведь химию',
            start: 15.1,
            end: 15.7,
          },
          {
            id: 4,
            text: 'я знаю',
            start: 16.1,
            end: 16.9,
          },
        ],
      },
      {
        startLineTime: 17.05,
        endLineTime: 20.3,
        parts: [
          {
            id: 1,
            text: 'Я тут',
            start: 17.05,
            end: 17.33,
          },
          {
            id: 2,
            text: 'работать',
            start: 17.5,
            end: 18.14,
          },
          {
            id: 3,
            text: 'хочу,',
            start: 18.3,
            end: 18.7,
          },
          {
            id: 4,
            text: 'амбиция',
            start: 18.8,
            end: 19.5,
            isHidden: true,
          },
          {
            id: 5,
            text: 'большая',
            start: 19.7,
            end: 20.3,
          },
        ],
      },
      {
        startLineTime: 20.4,
        endLineTime: 21.5,
        parts: [
          {
            id: 1,
            text: 'ААА',
            start: 20.4,
            end: 21.5,
          },
        ],
      },
      {
        startLineTime: 21.9,
        endLineTime: 24.05,
        parts: [
          {
            id: 1,
            text: 'у',
            start: 21.9,
            end: 22.4,
          },
          {
            id: 2,
            text: 'ага,',
            start: 22.5,
            end: 23.1,
          },
          {
            id: 3,
            text: 'е е',
            start: 23.2,
            end: 24.05,
          },
        ],
      },
      {
        startLineTime: 24.2,
        endLineTime: 25.3,
        parts: [
          {
            id: 1,
            text: 'ААА',
            start: 24.2,
            end: 25.3,
          },
        ],
      },
      {
        startLineTime: 25.7,
        endLineTime: 27.75,
        parts: [
          {
            id: 1,
            text: 'у',
            start: 25.7,
            end: 26.1,
          },
          {
            id: 2,
            text: 'ага,',
            start: 26.2,
            end: 26.8,
          },
          {
            id: 3,
            text: 'е е',
            start: 26.9,
            end: 27.75,
          },
        ],
      },
      {
        startLineTime: 28.1,
        endLineTime: 31.4,
        parts: [
          {
            id: 1,
            text: 'Наставник',
            start: 28.1,
            end: 28.6,
          },
          {
            id: 2,
            text: 'мне,',
            start: 28.7,
            end: 29.04,
          },
          {
            id: 3,
            text: 'совет дал,',
            start: 29.13,
            end: 29.73,
          },
          {
            id: 4,
            text: 'ему я',
            start: 29.9,
            end: 30.5,
          },
          {
            id: 5,
            text: 'доверяю',
            start: 30.5,
            end: 31.4,
          },
        ],
      },
      {
        startLineTime: 31.7,
        endLineTime: 35.1,
        parts: [
          {
            id: 1,
            text: 'Послушай',
            start: 31.7,
            end: 32.3,
          },
          {
            id: 2,
            text: 'мудрость',
            start: 32.4,
            end: 33,
          },
          {
            id: 3,
            text: 'его',
            start: 33.2,
            end: 33.5,
          },
          {
            id: 4,
            text: 'и будет',
            start: 33.6,
            end: 34.1,
          },
          {
            id: 5,
            text: 'все',
            start: 34.2,
            end: 34.4,
          },
          {
            id: 6,
            text: 'как надо',
            start: 34.5,
            end: 35.1,
          },
        ],
      },
      {
        startLineTime: 35.2,
        endLineTime: 36.3,
        parts: [
          {
            id: 1,
            text: 'ААА',
            start: 35.2,
            end: 36.3,
          },
        ],
      },
      {
        startLineTime: 36.8,
        endLineTime: 38.8,
        parts: [
          {
            id: 1,
            text: 'у',
            start: 36.8,
            end: 37.2,
          },
          {
            id: 2,
            text: 'ага,',
            start: 37.3,
            end: 37.7,
          },
          {
            id: 3,
            text: 'е е',
            start: 38,
            end: 38.8,
          },
        ],
      },
      {
        startLineTime: 38.9,
        endLineTime: 40,
        parts: [
          {
            id: 1,
            text: 'ААА',
            start: 38.9,
            end: 40,
          },
        ],
      },
      {
        startLineTime: 41.1,
        endLineTime: 44.7,
        parts: [
          {
            id: 1,
            text: 'Только',
            start: 41.1,
            end: 41.5,
          },
          {
            id: 2,
            text: 'постарайся,',
            start: 41.6,
            end: 43.5,
          },
          {
            id: 3,
            text: 'постарайся',
            start: 43.7,
            end: 44.7,
          },
        ],
      },
      {
        startLineTime: 44.8,
        endLineTime: 48.9,
        parts: [
          {
            id: 1,
            text: 'Словно',
            start: 44.8,
            end: 45.3,
          },
          {
            id: 2,
            text: 'полимер',
            start: 45.4,
            end: 45.9,
          },
          {
            id: 3,
            text: 'скорей',
            start: 46,
            end: 47,
          },
          {
            id: 4,
            text: 'растворяйся!',
            start: 47.3,
            end: 48.9,
          },
        ],
      },
      {
        startLineTime: 49.1,
        endLineTime: 52.7,
        parts: [
          {
            id: 1,
            text: 'Растворяйся',
            start: 49.1,
            end: 51.1,
          },

          {
            id: 2,
            text: 'растворяйся',
            start: 51.1,
            end: 52.7,
          },
        ],
      },
      {
        startLineTime: 53.8,
        endLineTime: 57,
        parts: [
          {
            id: 1,
            text: 'Рас',
            start: 53.8,
            end: 54.2,
          },
          {
            id: 2,
            text: 'рас',
            start: 54.3,
            end: 54.7,
          },
          {
            id: 3,
            text: 'рас',
            start: 54.8,
            end: 55.1,
          },
          {
            id: 4,
            text: 'растворяйся',
            start: 55.3,
            end: 57,
          },
        ],
      },
    ],
    answers: [
      { id: 1, text: 'Улыбайся - IOWA', isTrue: true },
      { id: 2, text: 'Кто ты? - Градусы', isTrue: false },
      { id: 3, text: 'Ты лучше - Маша Вебер', isTrue: false },
    ],
    words: [
      { id: 1, text: 'амбиция', isTrue: true },
      { id: 2, text: 'дерзость', isTrue: false },
      { id: 3, text: 'целеустремленность', isTrue: false },
    ],
    mutedStart: 18.5,
    mutedEnd: 19.5,
  },

  {
    id: 'kraimbrery',
    song: {
      mp4: kraimbreryMP4,
      webm: kraimbreryWEBM,
    },
    songTextLines: [
      {
        startLineTime: 16.7,
        endLineTime: 21.7,
        parts: [
          {
            id: 1,
            text: 'Экологический проект',
            start: 16.7,
            end: 18.7,
          },
          {
            id: 2,
            text: '- это то,',
            start: 19.1,
            end: 19.7,
          },
          {
            id: 3,
            text: 'что',
            start: 19.9,
            end: 20,
          },
          {
            id: 4,
            text: 'близко',
            start: 20.1,
            end: 20.45,
          },
          {
            id: 5,
            text: 'сердцу',
            start: 20.6,
            end: 21.7,
          },
        ],
      },
      {
        startLineTime: 25.1,
        endLineTime: 29.88,
        parts: [
          {
            id: 1,
            text: 'От сокращенья',
            start: 25.1,
            end: 26.2,
          },
          {
            id: 2,
            text: 'потребления',
            start: 26.5,
            end: 27.6,
            isHidden: true,
          },
          {
            id: 3,
            text: 'нам',
            start: 27.7,
            end: 28,
          },
          {
            id: 4,
            text: 'никуда',
            start: 28.2,
            end: 28.6,
          },
          {
            id: 5,
            text: 'не деться',
            start: 28.78,
            end: 29.88,
          },
        ],
      },
      {
        startLineTime: 31.9,
        endLineTime: 33.7,
        parts: [
          {
            id: 1,
            text: 'И солнечные',
            start: 31.9,
            end: 32.8,
          },
          {
            id: 2,
            text: 'батареи,',
            start: 33,
            end: 33.7,
          },
        ],
      },
      {
        startLineTime: 34.3,
        endLineTime: 36.2,
        parts: [
          {
            id: 1,
            text: 'И высадка',
            start: 34.3,
            end: 34.65,
          },
          {
            id: 2,
            text: 'вряд',
            start: 34.8,
            end: 34.95,
          },
          {
            id: 2,
            text: 'деревьев',
            start: 35.1,
            end: 36.2,
          },
        ],
      },
      {
        startLineTime: 36.5,
        endLineTime: 39.7,
        parts: [
          {
            id: 1,
            text: 'И надо',
            start: 36.5,
            end: 37,
          },
          {
            id: 2,
            text: 'сорт',
            start: 37.2,
            end: 37.4,
          },
          {
            id: 3,
            text: 'сортировать',
            start: 37.5,
            end: 38.55,
          },
          {
            id: 4,
            text: 'мусор',
            start: 38.8,
            end: 39.03,
          },
          {
            id: 5,
            text: 'свой',
            start: 39.2,
            end: 39.7,
          },
        ],
      },
      {
        startLineTime: 40.9,
        endLineTime: 44.1,
        parts: [
          {
            id: 1,
            text: 'И если',
            start: 40.9,
            end: 41.3,
          },
          {
            id: 2,
            text: 'хочешь',
            start: 41.42,
            end: 41.95,
          },
          {
            id: 3,
            text: 'давай',
            start: 42.1,
            end: 42.8,
          },
          {
            id: 4,
            text: 'присоединяйся',
            start: 42.9,
            end: 44.1,
          },
        ],
      },
      {
        startLineTime: 44.6,
        endLineTime: 46,
        parts: [
          {
            id: 1,
            text: 'Вместе',
            start: 44.6,
            end: 45,
          },
          {
            id: 2,
            text: 'со мной',
            start: 45.2,
            end: 46,
          },
        ],
      },
      {
        startLineTime: 48.5,
        endLineTime: 52.4,
        parts: [
          {
            id: 1,
            text: 'И каждый',
            start: 48.5,
            end: 49.2,
          },
          {
            id: 2,
            text: 'наш',
            start: 49.4,
            end: 49.6,
          },
          {
            id: 3,
            text: 'проект',
            start: 49.7,
            end: 40.4,
          },
          {
            id: 4,
            text: 'он ещё',
            start: 50.5,
            end: 51.15,
          },
          {
            id: 5,
            text: 'зеленей',
            start: 51.2,
            end: 52.4,
          },
        ],
      },
      {
        startLineTime: 52.5,
        endLineTime: 56.5,
        parts: [
          {
            id: 1,
            text: 'Это так',
            start: 52.5,
            end: 53.18,
          },
          {
            id: 2,
            text: 'хорошо',
            start: 53.3,
            end: 54.3,
          },
          {
            id: 3,
            text: 'для природы,',
            start: 54.6,
            end: 55.5,
          },
          {
            id: 4,
            text: 'поверь,',
            start: 55.7,
            end: 56.5,
          },
        ],
      },
      {
        startLineTime: 56.9,
        endLineTime: 60.8,
        parts: [
          {
            id: 1,
            text: 'И каждый',
            start: 56.9,
            end: 57.6,
          },
          {
            id: 2,
            text: 'наш',
            start: 57.7,
            end: 57.9,
          },
          {
            id: 3,
            text: 'проект',
            start: 58,
            end: 58.7,
          },
          {
            id: 4,
            text: 'он ещё',
            start: 58.8,
            end: 59.45,
          },
          {
            id: 5,
            text: 'зеленей',
            start: 59.6,
            end: 60.8,
          },
        ],
      },
      {
        startLineTime: 60.8,
        endLineTime: 64.7,
        parts: [
          {
            id: 1,
            text: 'Сохраняем',
            start: 60.8,
            end: 61.8,
          },
          {
            id: 2,
            text: 'планету',
            start: 61.9,
            end: 62.9,
          },
          {
            id: 3,
            text: 'для людей',
            start: 63,
            end: 63.7,
          },
          {
            id: 4,
            text: 'и зверей',
            start: 63.8,
            end: 64.7,
          },
        ],
      },
      {
        startLineTime: 65.3,
        endLineTime: 69.1,
        parts: [
          {
            id: 1,
            text: 'И каждый',
            start: 65.3,
            end: 65.9,
          },
          {
            id: 2,
            text: 'наш',
            start: 66.1,
            end: 66.3,
          },
          {
            id: 3,
            text: 'проект',
            start: 66.4,
            end: 67,
          },
          {
            id: 4,
            text: 'он ещё',
            start: 67.1,
            end: 67.75,
          },
          {
            id: 5,
            text: 'зеленей',
            start: 67.9,
            end: 69.1,
          },
        ],
      },
      {
        startLineTime: 69.2,
        endLineTime: 73.2,
        parts: [
          {
            id: 1,
            text: 'Это так',
            start: 69.2,
            end: 69.88,
          },
          {
            id: 2,
            text: 'хорошо',
            start: 70,
            end: 71,
          },
          {
            id: 3,
            text: 'для природы,',
            start: 71.2,
            end: 72.1,
          },
          {
            id: 4,
            text: 'поверь,',
            start: 72.3,
            end: 73.2,
          },
        ],
      },
      {
        startLineTime: 73.6,
        endLineTime: 77.5,
        parts: [
          {
            id: 1,
            text: 'И каждый',
            start: 73.6,
            end: 74.2,
          },
          {
            id: 2,
            text: 'наш',
            start: 74.4,
            end: 74.6,
          },
          {
            id: 3,
            text: 'проект',
            start: 74.7,
            end: 75.3,
          },
          {
            id: 4,
            text: 'он ещё',
            start: 75.4,
            end: 76.05,
          },
          {
            id: 5,
            text: 'зеленей',
            start: 76.3,
            end: 77.5,
          },
        ],
      },
      {
        startLineTime: 77.6,
        endLineTime: 81.6,
        parts: [
          {
            id: 1,
            text: 'Сохраняем',
            start: 77.6,
            end: 78.5,
          },
          {
            id: 2,
            text: 'планету',
            start: 78.7,
            end: 79.7,
          },
          {
            id: 3,
            text: 'для наших',
            start: 79.8,
            end: 80.55,
          },
          {
            id: 4,
            text: 'детей',
            start: 80.7,
            end: 81.6,
          },
        ],
      },
    ],
    answers: [
      { id: 1, text: 'Как ты там - Ирина Дубцова', isTrue: false },
      { id: 2, text: 'Мне так хорошо - Мари Краймбрери', isTrue: true },
      { id: 3, text: 'Плачу на техно - Хлеб', isTrue: false },
    ],
    words: [
      { id: 1, text: 'распространения', isTrue: false },
      { id: 2, text: 'потребления', isTrue: true },
      { id: 3, text: 'поколения', isTrue: false },
    ],
    mutedStart: 26.2,
    mutedEnd: 27.6,
  },

  {
    id: 'tea-together',
    song: {
      mp4: teaTogetherMP4,
      webm: teaTogetherWEBM,
    },
    songTextLines: [
      {
        startLineTime: 18.25,
        endLineTime: 22.3,
        parts: [
          {
            id: 1,
            text: 'Как',
            start: 18.25,
            end: 18.55,
          },
          {
            id: 2,
            text: 'как',
            start: 18.6,
            end: 18.95,
          },
          {
            id: 3,
            text: 'парарам',
            start: 19,
            end: 19.9,
          },
          {
            id: 4,
            text: 'оказался',
            start: 20.2,
            end: 21.5,
          },
          {
            id: 5,
            text: 'я здесь?',
            start: 21.5,
            end: 22.3,
          },
        ],
      },
      {
        startLineTime: 25.5,
        endLineTime: 30.4,
        parts: [
          {
            id: 1,
            text: 'Для',
            start: 25.5,
            end: 26.1,
          },
          {
            id: 2,
            text: 'инженера',
            start: 26.1,
            end: 27,
            isHidden: true,
          },
          {
            id: 3,
            text: 'условия',
            start: 27.1,
            end: 28.3,
          },
          {
            id: 4,
            text: 'лучшие',
            start: 28.4,
            end: 29.25,
          },
          {
            id: 5,
            text: 'здесь',
            start: 29.4,
            end: 30.4,
          },
        ],
      },
      {
        startLineTime: 34.15,
        endLineTime: 38.4,
        parts: [
          {
            id: 1,
            text: 'как',
            start: 34.15,
            end: 34.45,
          },
          {
            id: 2,
            text: 'как',
            start: 34.45,
            end: 34.7,
          },
          {
            id: 3,
            text: 'всем',
            start: 34.8,
            end: 35,
          },
          {
            id: 4,
            text: 'могу',
            start: 35.1,
            end: 35.8,
          },
          {
            id: 5,
            text: 'я объяснить',
            start: 36.3,
            end: 38.4,
          },
        ],
      },
      {
        startLineTime: 41.1,
        endLineTime: 46.4,
        parts: [
          {
            id: 1,
            text: 'что',
            start: 41.1,
            end: 41.33,
          },
          {
            id: 2,
            text: 'место',
            start: 41.4,
            end: 42.05,
          },
          {
            id: 3,
            text: 'это',
            start: 42.2,
            end: 42.7,
          },

          {
            id: 4,
            text: '— моя жизнь',
            start: 44.6,
            end: 46.4,
          },
        ],
      },
      {
        startLineTime: 49.8,
        endLineTime: 53.4,
        parts: [
          {
            id: 1,
            text: 'может',
            start: 49.8,
            end: 50.3,
          },
          {
            id: 2,
            text: 'показать',
            start: 50.5,
            end: 51.8,
          },
          {
            id: 3,
            text: 'им мою зп?',
            start: 51.9,
            end: 53.4,
          },
        ],
      },
      {
        startLineTime: 53.8,
        endLineTime: 57.5,
        parts: [
          {
            id: 1,
            text: 'может',
            start: 53.8,
            end: 54.3,
          },
          {
            id: 2,
            text: 'рассказать',
            start: 54.4,
            end: 55.6,
          },
          {
            id: 3,
            text: 'им про',
            start: 55.8,
            end: 56.18,
          },
          {
            id: 4,
            text: 'соц пакет?',
            start: 56.4,
            end: 57.5,
          },
        ],
      },
      {
        startLineTime: 57.8,
        endLineTime: 61.3,
        parts: [
          {
            id: 1,
            text: 'может про завод?',
            start: 57.8,
            end: 59.4,
          },
          {
            id: 2,
            text: 'может про народ?',
            start: 59.8,
            end: 61.3,
          },
        ],
      },
      {
        startLineTime: 61.8,
        endLineTime: 65,
        parts: [
          {
            id: 1,
            text: 'может я спою',
            start: 61.8,
            end: 63.3,
          },
          {
            id: 2,
            text: 'и узнают все?',
            start: 63.6,
            end: 65,
          },
        ],
      },
      {
        startLineTime: 65.1,
        endLineTime: 68.4,
        parts: [
          {
            id: 1,
            text: 'компания',
            start: 65.1,
            end: 66.4,
          },
          {
            id: 2,
            text: 'моя',
            start: 66.6,
            end: 66.9,
          },
          {
            id: 3,
            text: 'масштабная',
            start: 67.1,
            end: 68.4,
          },
        ],
      },
      {
        startLineTime: 69.4,
        endLineTime: 72.9,
        parts: [
          {
            id: 1,
            text: 'нефтехимическая,',
            start: 69.4,
            end: 71.2,
          },
          {
            id: 2,
            text: 'экологичная',
            start: 71.5,
            end: 72.9,
          },
        ],
      },
      {
        startLineTime: 73.1,
        endLineTime: 76.4,
        parts: [
          {
            id: 1,
            text: 'стремительная,',
            start: 73.1,
            end: 74.5,
          },
          {
            id: 2,
            text: 'стабильная',
            start: 74.9,
            end: 76.4,
          },
        ],
      },
      {
        startLineTime: 77.2,
        endLineTime: 80.8,
        parts: [
          {
            id: 1,
            text: 'карьерный',
            start: 77.2,
            end: 77.85,
          },
          {
            id: 2,
            text: 'рост',
            start: 78,
            end: 78.3,
          },
          {
            id: 3,
            text: 'большой',
            start: 78.4,
            end: 79.1,
          },
          {
            id: 4,
            text: 'будет',
            start: 79.2,
            end: 79.52,
          },
          {
            id: 5,
            text: 'всегда',
            start: 79.7,
            end: 80.1,
          },
          {
            id: 6,
            text: 'с тобой',
            start: 80.3,
            end: 80.8,
          },
        ],
      },
      {
        startLineTime: 81,
        endLineTime: 84.3,
        parts: [
          {
            id: 1,
            text: 'компания',
            start: 81,
            end: 82.2,
          },
          {
            id: 2,
            text: 'моя',
            start: 82.4,
            end: 82.7,
          },
          {
            id: 3,
            text: 'масштабная',
            start: 82.9,
            end: 84.3,
          },
        ],
      },
      {
        startLineTime: 85.3,
        endLineTime: 88.7,
        parts: [
          {
            id: 1,
            text: 'нефтехимическая,',
            start: 85.3,
            end: 87.1,
          },
          {
            id: 2,
            text: 'экологичная',
            start: 87.3,
            end: 88.7,
          },
        ],
      },
      {
        startLineTime: 88.9,
        endLineTime: 92.1,
        parts: [
          {
            id: 1,
            text: 'стремительная,',
            start: 88.9,
            end: 90.3,
          },
          {
            id: 2,
            text: 'стабильная',
            start: 90.6,
            end: 92.1,
          },
        ],
      },
      {
        startLineTime: 93,
        endLineTime: 96.7,
        parts: [
          {
            id: 1,
            text: 'карьерный',
            start: 93,
            end: 93.65,
          },
          {
            id: 2,
            text: 'рост',
            start: 93.85,
            end: 94.15,
          },
          {
            id: 3,
            text: 'большой',
            start: 94.2,
            end: 94.9,
          },
          {
            id: 4,
            text: 'будет',
            start: 95.1,
            end: 95.4,
          },
          {
            id: 5,
            text: 'всегда',
            start: 95.5,
            end: 95.9,
          },
          {
            id: 6,
            text: 'с тобой',
            start: 96.1,
            end: 96.7,
          },
        ],
      },
    ],
    answers: [
      { id: 1, text: 'Дыши - Серебро', isTrue: false },
      { id: 2, text: 'Девочка с севера - Премьер-Министр', isTrue: false },
      { id: 3, text: 'Ласковая моя - Чай вдвоём', isTrue: true },
    ],
    words: [
      { id: 1, text: 'инженера', isTrue: true },
      { id: 2, text: 'полимера', isTrue: false },
      { id: 3, text: 'примера', isTrue: false },
    ],
    mutedStart: 26.1,
    mutedEnd: 27,
  },
];
