import { useEffect, useState } from 'react';
import styles from './SongText.module.scss';
import cn from 'classnames';
import { TSongTextLine } from '../../data';
import { useAppSelector } from '@/src/hooks';
import {
  currentLineIndexSelector,
  currentProgressPlayedSecondsSelector,
} from '@/src/store/selectors/progress';
import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import { addLineIndex } from '@/src/store/slices/progressSlice';

type TProps = {
  text: TSongTextLine[];
  className?: string;
  isGuessWordGameType?: boolean;
};

const getPercent = (start: number, end: number, seconds: number) => {
  if (seconds < start) {
    return 0;
  }
  if (seconds >= end) {
    return 100;
  }
  return (seconds - start) * (100 / (end - start));
};

export const SongText = ({ text, className, isGuessWordGameType }: TProps) => {
  const progressPlayedSeconds = useAppSelector(
    currentProgressPlayedSecondsSelector,
  );
  const currentIndex = useAppSelector(currentLineIndexSelector);

  const dispatch = useDispatch();

  const [nextLineTime, setNextLineTime] = useState(
    text[currentIndex].endLineTime,
  );
  const line = text[currentIndex];

  useEffect(() => {
    if (
      progressPlayedSeconds > nextLineTime &&
      currentIndex < text.length - 1
    ) {
      dispatch(addLineIndex());
    }
  }, [progressPlayedSeconds]);

  useEffect(() => {
    setNextLineTime(text[currentIndex].endLineTime);
  }, [currentIndex]);

  return (
    <div
      className={cn(styles.songText, {
        [`${className}`]: className !== undefined,
      })}
    >
      {line.parts.map(({ text, start, end, isHidden }) => (
        <>
          <span key={nanoid()} className={styles.songText__part}>
            <span
              className={cn(styles['songText__part-text'], {
                [styles['songText__part-text--hidden']]:
                  isGuessWordGameType && isHidden,
              })}
            >
              {text}
            </span>
            <span
              className={cn(styles['songText__part-progress'], {
                [styles['songText__part-progress--hidden']]:
                  isGuessWordGameType && isHidden,
              })}
              style={{
                backgroundImage: `linear-gradient(to right, #77E2C3 ${getPercent(
                  start,
                  end,
                  progressPlayedSeconds,
                )}%, transparent ${getPercent(
                  start,
                  end,
                  progressPlayedSeconds,
                )}%)`,
              }}
            >
              {text}
            </span>
          </span>
          <span className={styles['songText__whitespace']}>&#160;</span>
        </>
      ))}
    </div>
  );
};
