import styles from './Footer.module.scss';
import logo from '@/src/images/hh-logo.png';
import { Container } from '@/src/components/Container';
import cn from 'classnames';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <Container className={styles.footer__container}>
        <div className={styles.footer__copyright}>
          © СИБУР 2024&nbsp;&nbsp;|
        </div>
        <div className={styles.footer__brand}>
          <div className={styles['footer__brand-logo']}>
            <img src={logo} width={18} height={18} alt="" />
          </div>
          <div className={styles['footer__brand-text']}>Бренд-центр</div>
        </div>

        <a href="#" className={cn(styles.footer__link, styles['only-mobile'])}>
          Вакансии
        </a>
      </Container>
    </div>
  );
};
