import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  playedSeconds: number;
  played: number;
  loadedSeconds: number;
  loaded: number;
  lineIndex: number;
}

const initialState: IState = {
  playedSeconds: 0,
  played: 0,
  loadedSeconds: 0,
  loaded: 0,
  lineIndex: 0,
};

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    resetProgress(state) {
      state.playedSeconds = 0;
      state.played = 0;
      state.loadedSeconds = 0;
      state.loaded = 0;
      state.lineIndex = 0;
    },
    setPlayedSeconds(state, action: PayloadAction<number>) {
      state.playedSeconds = action.payload;
    },
    setPlayed(state, action: PayloadAction<number>) {
      state.played = action.payload;
    },
    setLoadedSeconds(state, action: PayloadAction<number>) {
      state.loadedSeconds = action.payload;
    },
    setLoaded(state, action: PayloadAction<number>) {
      state.loaded = action.payload;
    },
    addLineIndex(state) {
      state.lineIndex += 1;
    },
  },
});

export const {
  resetProgress,
  setPlayedSeconds,
  setPlayed,
  setLoadedSeconds,
  setLoaded,
  addLineIndex,
} = progressSlice.actions;

export default progressSlice;
