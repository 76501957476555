import { GAME_TYPE } from '@/src/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  gameType: keyof typeof GAME_TYPE | null;
  numberCorrectAnswers: number;
  songSlug?: string;
}

const initialState: IState = {
  gameType: null,
  numberCorrectAnswers: 0,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameType(state, action: PayloadAction<keyof typeof GAME_TYPE | null>) {
      state.gameType = action.payload;
    },
    setNumberCorrectAnswers(state, action: PayloadAction<number>) {
      state.numberCorrectAnswers += action.payload;
    },
    setSongSlug(state, action: PayloadAction<string>) {
      state.songSlug = action.payload;
    },
  },
});

export const { setGameType, setNumberCorrectAnswers, setSongSlug } =
  gameSlice.actions;

export default gameSlice;
