import styles from './SongCard.module.scss';
import cn from 'classnames';

type TProps = {
  onClick: (songSlug: string) => void;
  song: {
    id: string;
    artist: string;
    title: string;
  };
  className?: string;
};

export const SongCard = ({ song, className, onClick }: TProps) => {
  const { artist, title, id } = song;

  return (
    <div
      className={cn(styles.songCard, {
        [`${className}`]: className !== undefined,
      })}
      onClick={() => onClick(id)}
    >
      <div className={styles.songCard__artist}>{artist}</div>
      <div className={styles.songCard__title}>{title}</div>
      <div className={styles.songCard__arrow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <path
            // fill="#fff"
            d="M18.5 2A1.5 1.5 0 0 0 17 .5H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V2ZM3.06 18.06l15-15L15.94.94l-15 15 2.12 2.12Z"
          />
        </svg>
      </div>
    </div>
  );
};
