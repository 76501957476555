import styles from './Preloader.module.scss';
import { useEffect, useState } from 'react';

type TProps = {
  onPreloadEnded: () => void;
};

export const Preloader = ({ onPreloadEnded }: TProps) => {
  const [currentNumber, setCurrentNumber] = useState(3);

  let timerId: ReturnType<typeof setTimeout> | null;

  useEffect(() => {
    const timer = () => {
      if (currentNumber <= 1) {
        timerId && clearInterval(timerId);

        onPreloadEnded();

        return;
      }

      setCurrentNumber((prev) => prev - 1);
    };

    timerId = setInterval(timer, 1000);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [currentNumber]);

  return (
    <div className={styles.preloader}>
      <div className={styles.preloader__count}>{currentNumber}</div>
    </div>
  );
};
