import { useMemo, useState } from 'react';
import { Game } from '../../pages/Game';
import { Home } from '../../pages/Home';
import styles from './App.module.scss';
import { Final } from '../Final';
import { PageContext } from './PageContext';

export const App = () => {
  const [page, setPage] = useState('home'); // home / game

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home />;
      case 'game':
        return <Game />;
      case 'final':
        return <Final />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  return (
    <PageContext.Provider value={{ page, setPage }}>
      <div className={styles.wrapper}>{renderCurrentPage}</div>
    </PageContext.Provider>
  );
};
