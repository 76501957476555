import { Footer } from '@/src/pages/Home/components/Footer';
import styles from './Final.module.scss';
import { Header } from '@/src/pages/Home/components/Header';
import { Container } from '../Container';
import { useAppSelector } from '@/src/hooks';
import {
  currentGameTypeSelector,
  currentNumberCorrectAnswersSelector,
} from '@/src/store/selectors/game';
import { GAME_TYPE } from '@/src/constants';
import { RESULTS } from './data';

const getResultVariant = (type: string, count: number) => {
  if (type === GAME_TYPE.SING) {
    return RESULTS['forSing'];
  }
  if (count >= 5) {
    return RESULTS['excellent'];
  }
  if (count >= 2 && count <= 4) {
    return RESULTS['satisfactory'];
  }
  return RESULTS['badly'];
};

export const Final = () => {
  const gameType = useAppSelector(currentGameTypeSelector) || '';
  const numberCorrectAnswers = useAppSelector(
    currentNumberCorrectAnswersSelector,
  );

  const resultVariant = getResultVariant(gameType, numberCorrectAnswers);

  return (
    <>
      <div className={styles.final}>
        <Header />

        <div className={styles.final__wrapper}>
          <Container className={styles.final__container}>
            <div className={styles.final__text}>
              <span className={styles.final__mark}>
                {resultVariant.markedText}
              </span>{' '}
              {resultVariant.text}
            </div>
            <div className={styles.final__appeal}>{resultVariant.appeal}</div>
            <a className={styles.final__button} href="#" target="_blank">
              <span className={styles['final__button-header']}>
                <span className={styles['final__button-logo']}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clipPath="url(#a)">
                      <path
                        fill="#DE1C12"
                        d="M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25Z"
                      />
                      <path
                        fill="#fff"
                        d="M7.11 6.859v4.252c.647-.772 1.423-1.16 2.323-1.16.464 0 .879.088 1.251.264.373.174.653.398.841.67.19.274.318.575.387.906.069.33.103.843.103 1.539v4.546h-2.011v-4.095c0-.812-.037-1.327-.113-1.546a.998.998 0 0 0-.404-.52c-.194-.131-.435-.196-.726-.196-.333 0-.632.084-.892.25-.266.166-.456.415-.577.748-.123.333-.185.825-.182 1.477l-.003 3.882H5.1V6.859H7.11Zm7.92 0v4.252c.648-.772 1.423-1.16 2.323-1.16.462 0 .88.088 1.253.264.372.174.651.398.838.67.19.273.321.58.387.906.07.33.104.843.104 1.539v4.546h-2.01v-4.095c0-.812-.037-1.327-.114-1.546a.994.994 0 0 0-.405-.52c-.191-.131-.434-.196-.726-.196-.332 0-.631.084-.892.25-.262.166-.453.415-.576.748-.12.333-.182.825-.182 1.477v3.882H13.02V6.859h2.009Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="#fff" d="M0 0h25v25H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span className={styles['final__button-arrow']}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      fill="#fff"
                      d="M18.5 1.5A1.5 1.5 0 0 0 17 0H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V1.5ZM3.06 17.56l15-15L15.94.44l-15 15 2.12 2.12Z"
                    />
                  </svg>
                </span>
              </span>
              <span className={styles['final__button-text']}>Голосовать</span>
            </a>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
};
