import { ReactNode } from 'react';
import styles from './Container.module.scss';
import cn from 'classnames';

type TProps = {
  className?: string;
  children: ReactNode;
};

export const Container = ({ children, className }: TProps) => {
  return (
    <div
      className={cn(styles.container, {
        [`${className}`]: className !== undefined,
      })}
    >
      {children}
    </div>
  );
};
