import styles from './GameProgress.module.scss';
import cn from 'classnames';

type TProps = {
  // onEnd: () => void;
  currentCount: number;
  totalCount: number;
  className?: string;
};

export const GameProgress = ({
  currentCount,
  totalCount,
  className,
}: TProps) => {
  return (
    <div
      className={cn(styles.progress, {
        [`${className}`]: className !== undefined,
      })}
    >
      {currentCount}/{totalCount} песен
    </div>
  );
};
