import styles from './Header.module.scss';
import logo from '@/src/images/logo.svg';
import { Container } from '@/src/components/Container';
import cn from 'classnames';

export const Header = () => {
  return (
    <div className={styles.header}>
      <Container className={styles.header__container}>
        <div className={styles.header__logo}>
          <img src={logo} width="216" height="42" alt="" />
        </div>
        <div className={styles.header__links}>
          <a
            href="#"
            className={cn(styles.header__link, styles['only-desktop'])}
          >
            Вакансии
          </a>
          <a href="#" className={styles.header__link}>
            Голосовать
          </a>
        </div>
      </Container>
    </div>
  );
};
