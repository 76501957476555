import styles from './Progress.module.scss';
import cn from 'classnames';

type TProps = {
  // onEnd: () => void;
  // currentCount: number;
  progress: number;
  className?: string;
};

export const Progress = ({ progress, className }: TProps) => {
  return (
    <div
      className={cn(styles.progress, {
        [`${className}`]: className !== undefined,
        ['ending-soon']: progress >= 80,
      })}
    >
      <div
        className={styles.progress__inner}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
